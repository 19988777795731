import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { API_BASE } from '../../env';
import { IconButton, TextField, Tooltip } from '@material-ui/core';
import { GetAppOutlined, HourglassEmptyOutlined } from '@material-ui/icons';

const FuelSupplyPage = () => {
  const [rowData, setRowData] = useState([]);
  const gridRef = useRef();
  const [loading, setLoading] = useState(false);

  const columnDefs = [
    { headerName: 'NÚMERO DE VIN', field: 'vin_number' },
    { headerName: 'MARCA', field: 'brand' },
    { headerName: 'MODELO', field: 'model' },
    { headerName: 'TIPO', field: 'fuel_type'},
    { headerName: 'CANTIDAD EN LITROS', field: 'liters'},
    {
      headerName: 'FECHA',
      field: 'date',
      valueFormatter: params => moment.utc(params.value).format('DD/MM/YYYY HH:mm'), // Formateo de fecha con hora en horario local usando moment
    },
    { headerName: 'OBSERVACIONES', field: 'remark' },
  ];

  const handleGetData = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const token = localStorage.getItem("token");

    config.headers["Authorization"] = `Token ${token}`;

    try {
      const response = await axios.get(`${API_BASE}v1/fuel-supply/`, config);
      setRowData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const handleDownload = async () => {

    setLoading(true)
    const config = {
      responseType: "blob",
      headers: { "Content-Type": "application/json" }
    };
    const token = localStorage.getItem("token");

    config.headers["Authorization"] = `Token ${token}`;

    await axios
      .get(`${API_BASE}v1/fuel-supply/download/`, config)
      .then(response => {
        let blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"
        });

        let fileURL = window.URL.createObjectURL(blob);
        let fileLink = document.createElement("a");
        fileLink.href = fileURL;

        fileLink.download = "ReporteSuministro8000S0.xlsx";
        fileLink.click();
      })
      .catch(error => {
        console.log(error.response.data);
      })
      .finally(() => {
        setLoading(false)
      });
  };

  useEffect(() => {
    handleGetData();
  }, []);

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById('filter-text-box-fs').value
    );
  })

  return (
    <div style={{ display: 'grid' }}>
      <div style={{
        display:'flex',
        justifyContent:'space-between',
        marginBottom: '20px'
        }}
      >
        <TextField
          label="Filtro Rápido"
          id='filter-text-box-fs'
          name="schoolName"
          type="text"
          variant="outlined"
          pattern="text"
          size="small"
          // className={classes.input}
          onChange={onFilterTextBoxChanged}
          InputLabelProps={{
            shrink: true
          }}
          placeholder={'Buscar...'}
          // value={vinNumber}
        />

        <>
          <Tooltip title="Descargar">
            <IconButton
              onClick={() => handleDownload()}
              disabled={loading}
            >
              {(loading) ? <HourglassEmptyOutlined/> : <GetAppOutlined />}
            </IconButton>
          </Tooltip>
        </>
      </div>
      <div className="ag-theme-alpine" style={{ height: 600, width: '100wh' }}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          pagination={true}
          paginationPageSize={50}
        />
      </div>
    </div>
  );
};

export default FuelSupplyPage;
